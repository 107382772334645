import { createStyles } from "@mantine/core";

export const useLinkStyles = createStyles((theme) => ({
  root: {
    color: theme.colors.brand[3],
    display: "inline-block",
    margin: 0,
    padding: 0,
    textDecoration: "none",
    transition: `color ${theme.other.animation.duration.interaction}ms`,

    "&:hover": {
      color: theme.fn.darken(theme.colors.brand[3], 0.2),
    },
  },
}));
