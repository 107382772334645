import { Center, Flex, Stack, useMantineTheme } from "@mantine/core";
import { PropsWithChildren, ReactElement } from "react";
import Wordmark from "@common/components/icons/Wordmark";
import { useContentStyles } from "./CenteredLayout.styled";
import { ICenteredLayoutProps } from "./types";

export default function CenteredLayout({
  maxWidth,
  padding,
  wordmark,
  children,
}: PropsWithChildren<ICenteredLayoutProps>): ReactElement {
  const theme = useMantineTheme();
  const { classes } = useContentStyles({ padding });

  return (
    <Flex direction="column" h="100vh" w="100%">
      {wordmark && (
        <Center py="xs">
          <Wordmark />
        </Center>
      )}
      <Center className={classes.root}>
        <Stack maw={maxWidth || theme.breakpoints.xs} w="100%">
          {children}
        </Stack>
      </Center>
    </Flex>
  );
}
