import Logo from "@common/components/icons/Logo";
import Link from "next/link";
import { ReactElement } from "react";
import { useLinkStyles } from "./Wordmark.styled";

export default function Wordmark(): ReactElement {
  const { classes } = useLinkStyles();

  return (
    <Link className={classes.root} href="/">
      <Logo width={39} height={47} />
    </Link>
  );
}
