import { createStyles } from "@mantine/core";
import { ICenteredLayoutContentProps } from "./types";

export const useContentStyles = createStyles(
  (theme, { padding }: ICenteredLayoutContentProps) => ({
    root: {
      flex: "1 0 auto",
      padding:
        padding === undefined || padding === null
          ? `${theme.spacing.lg}px ${theme.spacing.xl}px`
          : padding,
    },
  }),
);
