import Logo from "@common/components/icons/Logo";
import { useSessionStore } from "@stores/session";
import { isEmail } from "@common/utils/string";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState, useEffect, useCallback, ReactElement } from "react";
import { useForm } from "react-hook-form";
import Form from "@common/components/ui/form/Form";
import {
  persistUTMQueryParamsToString,
  persistUTMQueryParamsToUrlObject,
} from "@common/utils/router";
import {
  Anchor,
  Button,
  Center,
  Flex,
  Stack,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import CenteredLayout from "@common/components/thirdParty/mantine/layout/CenteredLayout";
import { IDataFetcherResponseError } from "@common/utils/use-query/types";
import { DEFAULT_ERROR_MESSAGE } from "@common/utils/use-query/constants";
import ErrorMessage from "@common/components/text/ErrorMessage";

export default function IndexPage(): ReactElement {
  const [loginError, setLoginError] =
    useState<IDataFetcherResponseError | null>(null);

  const router = useRouter();
  const theme = useMantineTheme();

  const { loading, account, login } = useSessionStore((state) => ({
    loading: state.loading,
    account: state.account,
    login: state.login,
  }));

  const {
    register,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    shouldFocusError: true,
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const onSubmit = useCallback(
    async (details) => {
      const { success, error } = await login(details);
      if (success) {
        setLoginError(null);
      } else {
        setLoginError(error);
      }
    },
    [login],
  );

  useEffect(() => {
    if (account) {
      const { query } = router;
      const { redirectTo } = query;

      router.replace(
        persistUTMQueryParamsToUrlObject(
          router,
          redirectTo ? String(redirectTo) : "/account",
        ),
      );
    }
  }, [account, router]);

  if (account) {
    return <></>;
  }

  return (
    <CenteredLayout maxWidth="100%" padding={0}>
      <Flex
        align="center"
        direction={{ base: "column", sm: "row" }}
        justify="space-between"
      >
        {/* Taglines */}
        <Center
          bg="gray.0"
          pl={{ base: theme.spacing.xl * 2, lg: "10%" }}
          pr={theme.spacing.xl * 2}
          py={{ base: theme.spacing.xl * 3, sm: "md" }}
          w={{ base: "100%", sm: "50%" }}
        >
          <Stack w="100%">
            <Flex ml={-15}>
              <Logo width={105} height={105} />
            </Flex>
            <Title color="brand.4" fw={700} m={0} order={1} size="h3">
              Create an account to get started with Metabase
            </Title>
            <Text color="gray.4" fz="sm">
              Get everything you need to start exploring data in five minutes
              with a 14-day trial.
            </Text>
            <div>
              <Button
                data-testid="get-started-button"
                component={Link}
                href={persistUTMQueryParamsToUrlObject(router, "/checkout")}
                mt="xs"
                py="xs"
                w="auto"
              >
                Get started
              </Button>
            </div>
          </Stack>
        </Center>

        {/* Login form */}
        <Center
          bg={theme.fn.lighten(theme.colors.brand[2], 0.75)}
          h={{ base: "auto", sm: "100vh" }}
          px={theme.spacing.xl * 2}
          py={{ base: theme.spacing.xl * 3, sm: "md" }}
          maw={{ base: "none", sm: 420 }}
          w="100%"
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing="sm">
              <Title color="brand.4" fw={700} m={0} order={2} size="h3">
                Log in to manage your Metabase account
              </Title>
              <Text color="gray.4" fz="sm">
                Manage your account, including billing preferences and technical
                settings.
              </Text>
              <TextInput
                id="email"
                type="email"
                label="Email address"
                placeholder="youlooknicetoday@email.com"
                onInput={() => setLoginError(null)}
                {...register("email", {
                  required: true,
                  validate(value) {
                    return isEmail(value) || "Invalid email";
                  },
                })}
              />
              <TextInput
                id="password"
                type="password"
                label="Password"
                placeholder="Shh..."
                onInput={() => setLoginError(null)}
                {...register("password", { required: true })}
              />
              {loginError && loginError.message !== "404" && (
                <ErrorMessage>
                  {loginError.message ||
                    loginError.status ||
                    DEFAULT_ERROR_MESSAGE}
                  {loginError.status === 401 && (
                    <>
                      &nbsp;You might also be{" "}
                      <Anchor
                        href={persistUTMQueryParamsToString(
                          router,
                          "https://www.metabase.com/cloud/login",
                        )}
                        target="_blank"
                        rel="noreferrer"
                        title="Metabase - Your instance"
                      >
                        looking for your instance
                      </Anchor>
                      ?
                    </>
                  )}
                </ErrorMessage>
              )}
              <Flex align="center" direction="row" justify="space-between">
                <Text fz="md">
                  <Link
                    href={persistUTMQueryParamsToUrlObject(
                      router,
                      "/forgot-password",
                    )}
                  >
                    Forgot password?
                  </Link>
                </Text>
                <Button
                  type="submit"
                  disabled={!isValid || loading}
                  loading={loading}
                >
                  Login
                </Button>
              </Flex>
            </Stack>
          </Form>
        </Center>
      </Flex>
    </CenteredLayout>
  );
}

IndexPage.hideNav = true;
